<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" title="Stranke">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" @click="$refs.modal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        Dodaj stranko
                    </b-button>
                </b-col>
            </b-row>
            <basic-table :columns="columns" :data="clients" v-slot="props" :enableRowMarking="true">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-button variant="warning" class="mr-0" @click="editClient(props.row.id)"><feather-icon icon="EditIcon"/></b-button>
                </span>
                <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                    <span v-if="props.row.tier === Tiers.Essential" style="color: #92d694">
                        Essential
                    </span>
                    <span v-else-if="props.row.tier === Tiers.Standard" style="color: #feb28d">
                        Standard
                    </span>
                    <span v-else-if="props.row.tier === Tiers.Premium" style="color: #ed603b">
                        Premium
                    </span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>

        <AddClient ref="modal" v-on:itemAdded="loadData" :resellers="resellers"/>
    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import AddClient from '@/views/Clients/AddClient'
    import {BButton, BCard, BCol, BOverlay, BRow} from 'bootstrap-vue'
    import {Tiers} from '@/libs/enums/Tiers'

    export default {
        computed: {
            Tiers() {
                return Tiers
            }
        },
        components: {BasicTable, AddClient, BRow, BCol, BCard, BOverlay, BButton},
        data() {
            return {
                clients: [],
                resellers: [],
                columns: [
                    {
                        label: 'Naziv',
                        field: 'name',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Alias',
                        field: 'alias',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Naslov',
                        field: 'address',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Verzija',
                        field: 'tier',
                        displayType: 3,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['Essential', 'Standard', 'Premium'],
                            filterFn: this.tiersFilter
                        }
                    },
                    {
                        label: 'Možnosti',
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ],
                showLoader: false
            }
        },
        methods: {
            editClient(client_id) {
                this.$router.push({name: 'edit_client', params: { client_id}})
            },
            tiersFilter(data, filterString) {
                if (filterString === 'Essential' && data === Tiers.Essential) {
                    return true
                } else if (filterString === 'Standard' && data === Tiers.Standard) {
                    return true
                } else if (filterString === 'Premium' && data === Tiers.Premium) {
                    return true
                } 

                return false
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const responseClient = this.$http.get('/api/admin/v1/client/')
                    const responseReseller = this.$http.get('/api/admin/v1/resellers/')

                    const responses = await Promise.all([responseClient, responseReseller])
                    this.clients = responses[0].data ?? []
                    this.resellers = responses[1].data ?? []
                } catch {
                    this.$printError('Prišlo je do napake', 3000)
                } finally {
                    this.showLoader = false
                }
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog('Ali res želite izbrisati vrstico?')
                    if (!confirmDelete) return
                    await this.$http.delete(`api/admin/v1/client/${id}`)
                    this.$printSuccess('Brisanje je bilo uspešno!', 3000)
                    this.loadData()
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error, 3000)

                }
            }
        },
        async mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>
</style>
