export const Locales = {
    Slovenian:'sl',
    Croatian:'hr',
    English:'en'
}
export const Countries = {
    Slovenia:1,
    Croatia:2,
    Syria:3,
    Lebanon:4,
    SierraLeone:5
}

import sl from '@/assets/images/flags/sl.png'
import en from '@/assets/images/flags/en.png'
import hr from '@/assets/images/flags/hr.png'
import srl from '@/assets/images/flags/srl.png'
import sy from '@/assets/images/flags/sy.png'
import lb from '@/assets/images/flags/lb.png'


export const localeObjects = () => {
    return [
        {id: Locales.Slovenian, label: 'Slovenščina', img:sl},
        {id: Locales.Croatian, label: 'Hrvaščina', img: hr},
        {id: Locales.English, label: 'Angleščina', img: en}
    ]
}
export const countryObjects = () => {
    return [
        {id: Countries.Slovenia, label: 'Slovenija', img:sl},
        {id: Countries.Croatia, label: 'Hrvaška', img: hr},
        {id: Countries.SierraLeone, label: 'Sierra Leone', img: srl},
        {id: Countries.Syria, label: 'Syria', img: sy},
        {id: Countries.Lebanon, label: 'Lebanon', img: lb}
    ]
}
